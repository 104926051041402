<template>
  <div class="container-fluid">
    <div class="row clearfix">
      <div v-if="contact.form_name" class="column full">
        <h3 class="iv2-contact-form__title">{{ contact.form_name }}</h3>
      </div>
      <div v-if="contact.form_description" class="column full">
        <p class="iv2-contact-form__summary text-pre">
          {{ contact.form_description }}
        </p>
      </div>
      <div class="column full">
        <div class="spacer height-20"></div>
      </div>
      <form @submit.prevent="submit">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :background-color="bgColor"
        ></loading>
        <div v-if="success" class="iv2-contact-form__response-text text-pre">
          {{ $store.state.publicLang.contact.send_text }}
        </div>
        <div
          v-else-if="timeout"
          class="iv2-contact-form__response-text text-pre"
        >
          {{ $store.state.publicLang.contact.send_error }}
        </div>
        <div v-else-if="invalid" class="m-4 text-danger text-pre">
          {{ $store.state.publicLang.contact.send_invalid }}
        </div>
        <div class="column full">
          <label class="d-block mt-3">
            <span class="badge badge-info mr-2">{{
              $store.state.publicLang.general.any
            }}</span>
            <span>{{ $store.state.publicLang.contact.customer_company }}</span>
          </label>
          <input
            type="text"
            class="iv2-contact-form__item w-75 p-2"
            name="company"
            id="company"
            v-model="fields.company"
            :placeholder="$store.state.publicLang.contact.customer_company"
          />
          <div v-if="errors && errors.company" class="text-danger">
            {{ errors.company[0] }}
          </div>
          <p class="mt-1 mb-1">
            {{ $store.state.publicLang.contact.company_warning }}
          </p>
        </div>
        <div class="column full">
          <label class="d-block mt-3">
            <span class="badge badge-danger mr-2">{{
              $store.state.publicLang.general.required
            }}</span>
            <span>{{ $store.state.publicLang.contact.basic_name }}</span>
          </label>
          <input
            type="text"
            class="iv2-contact-form__item w-75 p-2"
            name="name"
            id="name"
            v-model="fields.name"
            :placeholder="$store.state.publicLang.contact.basic_name"
          />
          <div v-if="errors && errors.name" class="text-danger">
            {{ errors.name[0] }}
          </div>
        </div>
        <div class="column full">
          <label class="d-block mt-3">
            <span class="badge badge-danger mr-2">{{
              $store.state.publicLang.general.required
            }}</span>
            <span>{{ $store.state.publicLang.contact.tel }}</span>
          </label>
          <input
            type="tel"
            class="iv2-contact-form__item w-75 p-2"
            name="tel"
            id="tel"
            required="required"
            v-model="fields.tel"
            :placeholder="$store.state.publicLang.contact.tel"
          />
          <div v-if="errors && errors.tel" class="text-danger">
            {{ errors.tel[0] }}
          </div>
        </div>
        <div class="column full">
          <label class="d-block mt-3">
            <span class="badge badge-danger mr-2">{{
              $store.state.publicLang.general.required
            }}</span>
            <span>{{ $store.state.publicLang.contact.email }}</span>
          </label>
          <input
            type="email"
            class="iv2-contact-form__item w-75 p-2"
            name="email"
            id="email"
            v-model="fields.email"
            :placeholder="$store.state.publicLang.contact.email"
          />
          <div v-if="errors && errors.email" class="text-danger">
            {{ errors.email[0] }}
          </div>
        </div>
        <div class="column full">
          <label class="d-block mt-3">
            <span class="badge badge-danger mr-2">{{
              $store.state.publicLang.general.required
            }}</span>
            <span>{{ $store.state.publicLang.contact.method.text }}</span>
          </label>
          <label
            v-for="method in $store.state.publicLang.contact.method.list"
            :key="method.id"
            class="mb-0 mr-1"
          >
            <input
              type="radio"
              class="iv2-contact-form__item m-2"
              name="method"
              :value="method.value"
              v-model="fields.method"
            />{{ method.name }}
          </label>
          <div v-if="errors && errors.method" class="text-danger">
            {{ errors.method[0] }}
          </div>
          <!-- customize -->
          <!-- <p class="mt-1 mb-1">
            {{ $store.state.publicLang.contact.method.warning }}
          </p> -->
        </div>
        <div class="column full">
          <label class="d-block mt-3">
            <span class="badge badge-danger mr-2">{{
              $store.state.publicLang.general.required
            }}</span>
            <span>{{ $store.state.publicLang.contact.message }}</span>
          </label>
          <textarea
            class="iv2-contact-form__item w-100 p-2"
            id="message"
            name="message"
            rows="5"
            v-model="fields.message"
          ></textarea>
          <div v-if="errors && errors.message" class="text-danger">
            {{ errors.message[0] }}
          </div>
        </div>
        <div class="column full">
          <p class="text-center mb-4 text-pre">
            {{ $store.state.publicLang.contact.warning }}
          </p>
          <div v-if="policy === 1">
            <p class="text-center mb-0">
              <router-link
                to="/privacypolicy"
                target="_blank"
                class="iv2-contact__privacy-link"
                >{{ $store.state.publicLang.contact.privacy_link }}</router-link
              >{{ $store.state.publicLang.contact.privacy_text }}
            </p>
            <div class="text-center">
              <label style="cursor: pointer">
                <input
                  type="checkbox"
                  name="agreement"
                  id="agreement"
                  v-model="fields.agreement"
                />{{ $store.state.publicLang.contact.agreement }}
                <div v-if="errors && errors.agreement" class="text-danger">
                  {{ errors.agreement[0] }}
                </div>
              </label>
            </div>
          </div>
          <p class="text-center mt-5">
            <input
              class="iv2-contact-form__btn theme__main-btn"
              type="submit"
              :value="$store.state.publicLang.contact.button"
              :disabled="!fields.agreement"
            />
          </p>
          <div class="spacer height-100"></div>
        </div>
        <div v-if="policy === 0">
          <input type="hidden" v-model="fields.agreement" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },

  props: {
    contact: Object,
  },

  data() {
    return {
      fields: {
        company: "",
        name: "",
        email: "",
        tel: "",
        method: "",
        message: "",
        agreement: "",
      },
      errors: {},
      policy: "",
      success: false,
      timeout: false,
      invalid: false,
      fullPage: true,
      isLoading: false,
      bgColor: "#525252",
    };
  },

  mounted() {
    const pagesRepository = this.$repository.get("pages");
    const pages = pagesRepository.show("/privacypolicy");
    const self = this;
    pages.then((result) => {
      self.policy = result.data.response.open_type;
      if (self.policy == 0) {
        self.fields.agreement = true;
      } else {
        self.fields.agreement = "";
      }
    });
  },

  methods: {
    submit() {
      this.isLoading = true;
      this.success = false;
      this.timeout = false;
      this.invalid = false;
      this.errors = {};

      const contactsRepository = this.$repository.get("contacts");
      const contacts = contactsRepository.customer(this.fields);
      const self = this;
      contacts
        .then(
          () => {
            self.fields = {
              company: "",
              name: "",
              email: "",
              tel: "",
              method: "",
              message: "",
            };
            if (self.policy == 0) {
              self.fields.agreement = true;
            } else {
              self.fields.agreement = "";
            }
            self.success = true;
          },
          (validated) => {
            console.log(validated);
            if (validated.response) {
              if (validated.response.status == 422) {
                self.errors = validated.response.data.errors || {};
                self.invalid = true;
              } else {
                self.timeout = true;
              }
            } else {
              self.timeout = true;
            }
          }
        )
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          self.isLoading = false;
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.iv2-contact-form__summary {
  letter-spacing: 3px;
}

.iv2-contact-form__response-text {
  background-color: #ddd;
  color: #000;
  margin: 1rem 0;
  padding: 0.75rem 1.25rem;
  border-radius: 5px;
}

.iv2-contact-form__item {
  border: 1px solid #8c8c8c;
}

.iv2-contact-form__btn {
  padding: 10px 80px;
  width: 300px;
  max-width: 100%;
}

input[type="submit"][disabled] {
  opacity: 0.2;

  &:hover {
    transform: scale(1);
    opacity: 0.2;
  }
}

@media (max-width: 1199px) {
  .iv2-contact__privacy-link {
    text-decoration: underline;
  }
}
</style>
